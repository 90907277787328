import React from 'react'
import {
  Heading,
  Box,
  Flex,
  Avatar,
  HStack,
  useColorModeValue as mode,
  Stack,
  Text,
  Img,
} from '@chakra-ui/react'
import Layout from 'components/Layout'
import Seo from 'components/Seo'
import { PageProps, graphql } from 'gatsby'
import isEmpty from 'lodash/isEmpty'

import { MDXRenderer } from 'gatsby-plugin-mdx'
import { BlogFull } from 'graphqlTypes'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import UnderlineLink from 'templates/UnderlineLink'
import { SocialLink } from './SocialLink'
import { FaFacebook, FaLinkedin, FaTwitter, FaViber } from 'react-icons/fa'
import MarkDownProvider from 'components/MarkDownProvider'
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from 'react-share'

interface Data {
  contentfulPost: BlogFull | null
}

const BlogPostPage: React.FC<PageProps<Data>> = (props) => {
  const post = props.data.contentfulPost
  if (props.data.contentfulPost?.createdAt) {
    dayjs.extend(customParseFormat)
  }
  console.log(props.location.href)
  return (
    <Layout>
      <Seo
        title={post?.title.title || ''}
        description={
          isEmpty(post?.description.description)
            ? undefined
            : post?.description.description
        }
      />
      <Box mt="10" ml={{ lg: '15rem' }} mr={{ lg: '15rem' }}>
        <Flex direction="column">
          <HStack textColor={mode('black', 'gray.50')}>
            <Box as="a">By </Box>
            {post?.author && (
              <Avatar
                size="xs"
                name={post.author.name}
                src={post.author.avatar.fixed.src}
                srcSet={post.author.avatar.fixed.srcSet}
              />
            )}
            {post?.author && (
              <Box as="a" fontWeight="bold">
                {post.author.name}
              </Box>
            )}
            {post?.createdAt && (
              <Box as="a">
                on {dayjs(post.createdAt, 'DD/MM/YYYY').format('MMM D, YYYY')}
              </Box>
            )}
          </HStack>
          <Heading
            fontSize="5xl"
            maxW="3xl"
            textColor={mode('black', 'gray.50')}
          >
            {post?.title.title}
          </Heading>
        </Flex>
      </Box>
      <Box
        mt="4"
        mb="4"
        lineHeight="6"
        ml={{ lg: '15rem' }}
        mr={{ lg: '15rem' }}
        fontWeight="semibold"
      >
        <MarkDownProvider>
          {post?.content.childMdx.body && (
            <MDXRenderer>{post?.content.childMdx.body}</MDXRenderer>
          )}
        </MarkDownProvider>
      </Box>
      <Box
        mt="20"
        mb="4"
        lineHeight="8"
        ml={{ lg: '15rem' }}
        mr={{ lg: '15rem' }}
      >
        <Stack spacing={6} h="100%" color={mode('gray.800', 'gray.300')}>
          <Box fontSize={{ base: 'md', md: 'lg' }} flex="1">
            <Text fontWeight="medium">
              Follow Walawong on{' '}
              <UnderlineLink href="https://twitter.com/walawong">
                Twitter
              </UnderlineLink>
              ,{' '}
              <UnderlineLink href="https://www.linkedin.com/company/walawong/">
                LinkedIn
              </UnderlineLink>{' '}
              and{' '}
              <UnderlineLink href="https://discord.gg/KnRcmBGtU8">
                Discord
              </UnderlineLink>
              {'.'}
            </Text>
          </Box>
          <HStack spacing={{ base: 3, md: 5 }}>
            {post && (
              <Img
                objectFit="cover"
                rounded="full"
                boxSize={14}
                src={post.author.avatar.fixed.src}
              />
            )}
            {post && (
              <Flex direction="column">
                <Text fontWeight="medium">{post.author.name}</Text>
                <Text fontSize="sm" fontWeight="medium" opacity={0.7}>
                  {post.author.position}
                </Text>
              </Flex>
            )}
            <Box w="12rem"></Box>
            <HStack mt="1" mb="6">
              <Text>Share: </Text>
              <WhatsappShareButton url={props.location.href}>
                <SocialLink
                  href=""
                  icon={FaViber}
                  label="Viber"
                  iconColor="#455A64"
                />
              </WhatsappShareButton>
              <FacebookShareButton url={props.location.href}>
                <SocialLink
                  href=""
                  icon={FaFacebook}
                  label="Facebook"
                  iconColor="#1877F2"
                />
              </FacebookShareButton>
              <TwitterShareButton url={props.location.href}>
                <SocialLink
                  href=""
                  icon={FaTwitter}
                  label="Twitter"
                  iconColor="#03A9F4"
                />
              </TwitterShareButton>
              <LinkedinShareButton url={props.location.href}>
                <SocialLink
                  href=""
                  icon={FaLinkedin}
                  label="LinkedIn"
                  iconColor="#0E76A8"
                />
              </LinkedinShareButton>
            </HStack>
          </HStack>
        </Stack>
      </Box>
    </Layout>
  )
}

export default BlogPostPage

export const pageQuery = graphql`
  query GetPostBySlug($slug: String!) {
    contentfulPost(slug: { eq: $slug }) {
      createdAt(formatString: "DD/MM/YYYY")
      slug
      title {
        title
      }
      author {
        name
        avatar {
          fixed(cropFocus: CENTER, height: 120, width: 120) {
            src
            srcSet
            width
            height
          }
        }
        position
      }
      heroImage {
        fixed(width: 1000) {
          src
          srcSet
          width
          height
        }
      }
      description {
        description
      }
      content {
        childMdx {
          body
        }
      }
    }
  }
`
