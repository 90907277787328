import React from 'react'
import { MDXProvider, MDXProviderComponents } from '@mdx-js/react'
import { Heading, Box, Link, Image, Center } from '@chakra-ui/react'
//import {} from 'gatsby-image'

const components: MDXProviderComponents = {
  h1: (props) => <Heading as="h1" py="2" size="2xl" {...props} />,
  h2: (props) => <Heading as="h2" py="2" size="xl" {...props} />,
  h3: (props) => <Heading as="h3" py="2" size="lg" {...props} />,
  h4: (props) => <Heading as="h4" py="2" size="md" {...props} />,
  h5: (props) => <Heading as="h5" py="2" size="sm" {...props} />,
  h6: (props) => <Heading as="h5" py="2" size="xs" {...props} />,
  p: (props) => <Box py="2" {...props} />,
  a: (props) => <Link colorScheme="brand" {...props} />,
  img: (props) => (
    <Center w="full">
      <Image {...props} />
    </Center>
  ),
}

const MarkDownProvider: React.FC = ({ children }) => {
  return <MDXProvider components={components}>{children}</MDXProvider>
}

export default MarkDownProvider
