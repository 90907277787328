import { BoxProps, Box, useColorModeValue } from '@chakra-ui/react'
import React from 'react'

export const SocialLinkIcon = (props: BoxProps) => (
  <Box
    transition="all 0.2s"
    _hover={{ color: useColorModeValue('gray.500', 'gray.300') }}
    fontSize="lg"
    color={useColorModeValue(props.color, 'gray.300')}
    {...props}
  />
)