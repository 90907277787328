import { Box, BoxProps, useColorModeValue as mode } from '@chakra-ui/react'
import * as React from 'react'

interface UnderlinkProps extends BoxProps {
    href?: string
}

function UnderlineLink(props: UnderlinkProps) {
    return (
        <Box
            as="a"
            href={props.href}
            target='_blank'
            pos="relative"
            display="inline-block"
            transition="opacity 0.2s"
            color={mode('brand.500', 'brand.500')}
            _hover={{ opacity: 0.8 }}
            _after={{
                content: `""`,
                display: 'block',
                w: 'full',
                h: '2px',
                bottom: 0,
                bg: 'brand.500',
                insetX: 0,
                insetY: 0,
            }}
            {...props}
        />
    )
}

export default UnderlineLink